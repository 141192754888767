import { IRouteConfig, IRoutingConfig } from '../../_shared/models/models-index';

export class RoutingConfig implements IRoutingConfig {

  public routes: IRouteConfig[] = [
    {
      id: 'leads',
      category: 'leads',
      default: true,
      defaultRoute: '/leads/overview',
      displayName: 'Leads',
      displayInNav: true,
      iconClass: 'fa fa-users',
      roles: undefined,
      children: [
        {
          parent: '/leads',
          route: 'overview',
          displayName: 'Overview',
          iconClass: 'fa fa-users',
          pdfEnabled: false,
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/leads',
          route: 'summary',
          displayName: 'Summary',
          iconClass: 'fa fa-list',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/leads',
          route: 'vehiclePerformance',
          displayName: 'Vehicle',
          iconClass: 'fa fa-car',
          breadcrumbHelpEnabled: true,
          roles: undefined,
        },
        {
          parent: '/leads',
          route: 'sourcePerformance',
          displayName: 'Source',
          iconClass: 'fa fa-list',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/leads',
          route: 'transactions',
          displayName: 'Transactions',
          iconClass: 'fa fa-exchange',
          breadcrumbHelpEnabled: true,
          roles: undefined,
        },
      ]
    },
    {
      id: 'website',
      category: 'website',
      defaultRoute: '/website/overview',
      displayName: 'Website',
      displayInNav: true,
      iconClass: 'fa fa-globe',
      roles: undefined,
      children: [
        {
          parent: '/website',
          route: 'overview',
          displayName: 'Overview',
          pdfEnabled: false,
          breadcrumbHelpEnabled: true,
          iconClass: 'fa fa-users',
          roles: undefined
        },
        {
          parent: '/website',
          route: 'scorecard',
          displayName: 'Scorecard',
          iconClass: 'fa fa-list',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/website',
          route: 'summary',
          displayName: 'Summary',
          iconClass: 'fa fa-list',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/website',
          route: 'referrer',
          displayName: 'Referrer',
          iconClass: 'fa fa-share',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/website',
          route: 'vehicle',
          displayName: 'Vehicle',
          iconClass: 'fa fa-car',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/website',
          route: 'provider',
          displayName: 'Provider',
          iconClass: 'fa fa-cogs',
          breadcrumbHelpEnabled: true,
          roles: ['System Administrator', 'Corporate'],
        },
      ]
    },
    {
      id: 'digAd',
      category: 'digAd',
      default: true,
      defaultRoute: '/digAd/overview',
      displayName: 'Digital Advertising',
      displayInNav: true,
      iconClass: 'fa fa-desktop',
      roles: undefined,
      children: [
        {
          parent: '/digAd',
          route: 'overview',
          displayName: 'Overview',
          pdfEnabled: false,
          iconClass: 'fa fa-users',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/digAd',
          route: 'scorecard',
          displayName: 'Scorecard',
          iconClass: 'fa fa-list',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/digAd',
          route: 'summary',
          displayName: 'Summary',
          iconClass: 'fa fa-list',
          breadcrumbHelpEnabled: true,
          roles: undefined
        },
        {
          parent: '/digAd',
          route: 'provider',
          displayName: 'Provider',
          iconClass: 'fa fa-cogs',
          breadcrumbHelpEnabled: true,
          roles: ['System Administrator', 'Corporate'],
        },
        {
          parent: '/digAd',
          route: 'channel',
          displayName: 'Channel',
          iconClass: 'fa fa-cogs',
          breadcrumbHelpEnabled: true,
          roles: undefined
        }
      ]
    },
    {
      id: 'provider-tools',
      category: 'provider-tools',
      default: true,
      defaultRoute: '/provider-tools/chat-overview',
      displayName: 'Tools',
      displayInNav: true,
      iconClass: 'fa fa-building',
      roles: undefined,
      children: [{
        parent: '/provider-tools',
        route: 'digital-retailing',
        displayName: 'Digital Retailing',
        pdfEnabled: false,
        iconClass: 'fa fa-users',
        breadcrumbHelpEnabled: true,
        roles: undefined
      }, {
        parent: '/provider-tools',
        route: 'chat-overview',
        displayName: 'Chat',
        pdfEnabled: false,
        iconClass: 'fa fa-users',
        breadcrumbHelpEnabled: true,
        roles: undefined
      },
      {
        parent: '/provider-tools',
        route: 'social-media',
        displayName: 'Social Media',
        iconClass: 'fa fa-cogs',
        breadcrumbHelpEnabled: true,
        roles: undefined
      },
      {
        parent: '/provider-tools',
        route: 'reputation-management',
        displayName: 'Reputation Management',
        iconClass: 'fa fa-cogs',
        breadcrumbHelpEnabled: true,
        roles: undefined
      }]
    },
    {
      id: 'sales',
      category: 'sales',
      default: true,
      defaultRoute: '/sales/overview',
      displayName: 'Sales',
      displayInNav: true,
      iconClass: 'fa fa-usd',
      roles: ['System Administrator', 'Corporate'],
      children: [
        {
          parent: '/sales',
          route: 'overview',
          displayName: 'Overview',
          pdfEnabled: false,
          iconClass: 'fa fa-users',
          breadcrumbHelpEnabled: true,
          roles: ['System Administrator', 'Corporate'],
        },
        {
          parent: '/sales',
          route: 'summary',
          displayName: 'Summary',
          iconClass: 'fa fa-list',
          breadcrumbHelpEnabled: true,
          roles: ['System Administrator', 'Corporate']
        },
        {
          parent: '/sales',
          route: 'vehicle',
          displayName: 'Vehicle',
          iconClass: 'fa fa-car',
          breadcrumbHelpEnabled: true,
          roles: ['System Administrator', 'Corporate'],
        }]
    },
    {
      id: 'externalTools',
      category: 'externalTools',
      defaultRoute: '',
      displayName: 'Other',
      displayInNav: true,
      iconClass: 'fa fa-external-link',
      roles: undefined,
      externalTool: true,
      disableForDealerGroups: true,
      children: [
        {
          parent: '/externalTools',
          route: 'retailerTools',
          displayName: 'Lead Settings',
          iconClass: 'fa fa-wrench',
          roles: undefined,
          breadcrumbHelpEnabled: true
        }
      ]
    },
    {
      id: 'management',
      category: 'management',
      defaultRoute: '/management/users',
      displayName: 'Management',
      displayInNav: true,
      iconClass: 'fa fa-cog',
      roles: ['System Administrator', 'System Analyst'],
      children: [
        {
          parent: '/management',
          route: 'content',
          displayName: 'Content',
          iconClass: 'fa fa-code',
              roles: ['System Administrator', 'System Analyst']
        },
        {
            parent: '/management',
            route: 'report-views-configuration',
            displayName: 'Report Views',
            iconClass: 'fa fa-code',
            roles: ['System Administrator', 'System Analyst'],
            children:
            [
              {
                id: 'report-views-configuration/create',
                parent: 'report-views-configuration',
                route: 'create',
                displayName: 'Create Report View',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              },
              {
                id: 'report-views-configuration/update',
                parent: 'report-views-configuration',
                route: 'update',
                displayName: 'Edit Report View',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              },
            ]
          }, {
            parent: '/management',
            route: 'data-sets-configuration',
            displayName: 'Data Sets',
            iconClass: 'fa fa-code',
            roles: ['System Administrator', 'System Analyst'],
            children:
            [
              {
                id: 'data-sets-configuration/create',
                parent: 'data-sets-configuration',
                route: 'create',
                displayName: 'Create Data Set',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              },
              {
                id: 'data-sets-configuration/update',
                parent: 'data-sets-configuration',
                route: 'update',
                displayName: 'Edit Data Set',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              },
            ]
          }, 
          {
            parent: '/management',
            route: 'query-configuration',
            displayName: 'Queries',
            iconClass: 'fa fa-code',
            roles: ['System Administrator', 'System Analyst'],
            children:
            [
              {
                id: 'query-configuration/create',
                parent: 'query-configuration',
                route: 'create',
                displayName: 'Create Query',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              },
              {
                id: 'query-configuration/update',
                parent: 'query-configuration',
                route: 'update',
                displayName: 'Edit Query',
                iconClass: 'fa fa-code',
                roles: ['System Administrator'],
              },
            ]
          },
         {
                parent: '/management',
                route: 'filter-configuration',
                displayName: 'Filters',
                iconClass: 'fa fa-code',
                roles: ['System Administrator', "System Analyst"],
                children:
                [
                {
                    id: 'filter-configuration/create',
                    parent: 'filter-configuration',
                    route: 'create',
                    displayName: 'Create Filter',
                    iconClass: 'fa fa-code',
                    roles: ['System Administrator'],
                },
                {
                    id: 'filter-configuration/update',
                    parent: 'filter-configuration',
                    route: 'update',
                    displayName: 'Edit Filter',
                    iconClass: 'fa fa-code',
                    roles: ['System Administrator'],
                },
                ]
          },
        {
          parent: '/management',
          route: 'users',
          displayName: 'Users',
          iconClass: 'fa fa-users',
            roles: ['System Administrator', 'System Analyst']
        }]
    }
  ];
}
