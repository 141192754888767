import { Enums, DateMonthModes } from '../../_shared/enums/enums';
import { environment } from '../../../environments/environment';
import { IFilterConfig } from '../../_shared/models/models-index';
import { Filter, FilterConfigReport, FilterType } from '../../_shared/filter/filter.model';
import * as constants from '../../_shared/constants/constants';
export class FilterConfig implements IFilterConfig {
  NUMBER_OF_MONTHS_TO_SHOW_DEFAULT = environment.numberOfMonthsToShowDefault;
  NUMBER_OF_QUARTERS_TO_SHOW_DEFAULT = environment.numberOfQuartersToShowDefault;
  hideCalendarToggle = true;
  showEntireOrgHierarchyForAllUsers = false;
  defaultMonthDateMode: DateMonthModes = 'calendar';
  filterTypeLookup: { [id: string]: FilterType } = {
    'org': { displayName: 'Hierarchy', name: 'org' },
    'campaign-type-v5': { displayName: 'Campaign Type', name: 'campaign-type-v5' },
    'channel': { displayName: 'Channel', name: 'channel' },
    'chat-provider': { displayName: 'Provider', name: 'chat-provider' },
    'device-type': { displayName: 'Device Type', name: 'device-type' },
    'digad-channel': { displayName: 'Channel', name: 'digad-channel' },
    'digad-provider-v5': { displayName: 'Provider', name: 'digad-provider-v5' },
    'digad-provider': { displayName: 'Provider', name: 'digad-provider' },
    'digital-retailing-provider': { displayName: 'Provider', name: 'digital-retailing-provider' },
    'lead-type': { displayName: 'Lead Type', name: 'lead-type' },
    'reputation-platform': { displayName: 'Platform', name: 'reputation-platform' },
    'reputation-provider': { displayName: 'Provider', name: 'reputation-provider' },
    'sale-type': { displayName: 'Sale Type', name: 'sale-type' },
    'social-media-platform': { displayName: 'Platform', name: 'social-media-platform' },
    'social-media-provider': { displayName: 'Provider', name: 'social-media-provider' },
    'source-type': { displayName: 'Source Type', name: 'source-type' },
    'vehicle': { displayName: 'Vehicle', name: 'vehicle' },
    'vehicle-class': { displayName: 'Vehicle', name: 'vehicle-class' },
    'vehicle-make': { displayName: 'Make', name: 'vehicle-make' },
    'vehicle-model': { displayName: 'Model', name: 'vehicle-model' },
    'website-provider': { displayName: 'Provider', name: 'website-provider' },
  };

  filterReportConfigs: { [id: string]: FilterConfigReport } = {
    // DigAd
    'DigitalAdvertisingOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.digitalAdvertisingOverview,
      filters: ['org', 'channel'],
      defaults: ['org', 'channel'],
      section: 'digital-advertising',
      orgTypes: [1]
    },
    'DigitalAdvertisingScorecard': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.digitalAdvertisingScorecard,
      filters: ['org', 'channel', 'digad-provider'],
      defaults: ['org', 'channel', 'digad-provider'],
      section: 'digital-advertising',
      orgTypes: [1]
    },
    'DigitalAdvertisingSummary': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.digitalAdvertisingSummary,
      filters: ['org', 'channel', 'digad-provider'],
      defaults: ['org', 'channel', 'digad-provider'],
      section: 'digital-advertising',
      orgTypes: [1]
    },
    'DigitalAdvertisingProvider': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.digitalAdvertisingProvider,
      filters: ['org', 'channel'],
      defaults: ['org', 'channel'],
      section: 'digital-advertising',
      orgTypes: [1]
    },
    'DigitalAdvertisingChannel': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.digitalAdvertisingChannel,
      filters: ['org', 'digad-provider'],
      defaults: ['org', 'digad-provider'],
      section: 'digital-advertising',
      orgTypes: [1]
    },
    // Leads
    'LeadsOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.leadsOverview,
      filters: ['org'],
      defaults: ['org'],
      section: 'leads'
    },
    'LeadSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.leadSummary,
      filters: [
        'org', 'lead-type', 'source-type'
      ],
      defaults: [
        'org', 'lead-type', 'source-type'
      ],
      section: 'leads',
      orgTypes: [1]
    },
    'LeadSourcePerformance': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.leadSourcePerformance,
      filters: [
        'org', 'lead-type', 'source-type', 'vehicle-model'
      ],
      defaults: [
        'org', 'lead-type', 'source-type'
      ],
      section: 'leads',
      orgTypes: [1]
    },
    'LeadVehiclePerformance': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.leadVehiclePerformance,
      filters: [
        'org', 'lead-type', 'source-type'
      ],
      defaults: [
        'org', 'lead-type', 'source-type'
      ],
      section: 'leads',
      orgTypes: [1]
    },
    'LeadTransactions': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.leadTransactions,
      filters: [
        'org', 'lead-type', 'source-type', 'vehicle-model'
      ],
      defaults: [
        'org', 'lead-type', 'source-type'
      ],
      section: 'leads',
      orgTypes: [1]
    },
    // Provider Tools
    'ChatOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.chatOverview,
      filters: ['org', 'device-type'],
      defaults: ['org'],
      section: 'provider-tools',
      orgTypes: [1]
    },
    'DigitalRetailingOverview': {
      reportName: constants.reportNames.digitalRetailingOverview,
      dateMode: Enums.dateModes.currentMonth,
      filters: ['org', 'digital-retailing-provider', 'device-type'],
      defaults: ['org'],
      section: 'provider-tools',
      orgTypes: [1]
    },
    'ReputationManagementOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.reputationManagementOverview,
      filters: ['org', 'reputation-provider', 'reputation-platform'],
      defaults: ['org', 'reputation-provider', 'reputation-platform'],
      section: 'provider-tools',
      orgTypes: [1]
    },
    'SocialMediaOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.socialMediaOverview,
      filters: ['org', 'social-media-provider', 'social-media-platform'],
      defaults: ['org', 'social-media-provider', 'social-media-platform'],
      section: 'provider-tools',
      orgTypes: [1]
    },
    // Sales
    'SalesOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.salesOverview,
      filters: ['org'],
      defaults: ['org'],
      section: 'sales',
      orgTypes: [1],
      // defaultMonthDateMode: 'sales'  Kia sales calendar will be hidden at launch
    },
    'SalesSummary': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.salesSummary,
      filters: [
        'org', 'lead-type', 'source-type'
      ],
      defaults: [
        'org', 'lead-type', 'source-type'
      ],
      section: 'sales',
      orgTypes: [1],
      // defaultMonthDateMode: 'sales'  Kia sales calendar be hidden at launch
    },
    'SalesVehicleSummary': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.salesVehicleSummary,
      filters: [
        'org', 'lead-type', 'source-type'
      ],
      defaults: [
        'org', 'lead-type', 'source-type'
      ],
      section: 'sales',
      orgTypes: [1],
      // defaultMonthDateMode: 'sales'  Kia sales calendar be hidden at launch
    },
    // Website
    'WebsiteOverview': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.websiteOverview,
      filters: ['org'],
      defaults: ['org'],
      section: 'website',
      orgTypes: [1]
    },
    'WebsiteProviderSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteProviderSummary, // org only
      filters: ['org'],
      defaults: ['org'],
      section: 'website',
      orgTypes: [1]
    },
    'WebsiteReferrerSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteReferrerSummary,
      filters: [
        'org', 'device-type'
      ],
      defaults: [
        'org', 'device-type'
      ],
      section: 'website',
      orgTypes: [1]
    },
    'WebsiteScorecard': {
      dateMode: Enums.dateModes.currentMonth,
      restrictDates: true,
      reportName: constants.reportNames.websiteScorecard,
      filters: [
        'org', 'website-provider'
      ],
      defaults: [
        'org', 'website-provider'
      ],
      section: 'website',
      orgTypes: [1]
    },
    'WebsiteSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteSummary,
      filters: [
        'org', 'website-provider', 'device-type'
      ],
      defaults: [
        'org', 'website-provider', 'device-type'
      ],
      section: 'website',
      orgTypes: [1]
    },
    'WebsiteVehicleSummary': {
      dateMode: Enums.dateModes.currentMonth,
      reportName: constants.reportNames.websiteVehicleSummary,
      filters: [
        'org', 'website-provider'
      ],
      defaults: [
        'org', 'website-provider'
      ],
      section: 'website',
      orgTypes: [1]
    }
  };

  FILTER_CONFIG: { [id: string]: Filter} = {
    'org': {
      type: 'org',
      selected: [],
      removable: false,
      lockable: true,
      locked: true
    },
    'vehicle': {
      type: 'vehicle',
      selected: [],
      removable: false,
      lockable: true,
      locked: true
    },
    'campaign-type-v5': {
      type: 'campaign-type-v5',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'channel': {
      type: 'channel',
      available: [2, 4, 5, 8],
      selected: [
        { value: 2, display: 'Search' },
        { value: 4, display: 'Display' },
        { value: 5, display: 'Video' },
        { value: 8, display: 'Social' },
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'chat-provider': {
      type: 'chat-provider',
      selected: [],
      removable: false,
      lockable: true,
      locked: false
    },
    'device-type': {
      type: 'device-type',
      selected: [
        { value: 1, display: 'Desktop' },
        { value: 2, display: 'Tablet' },
        { value: 3, display: 'Phone' }
      ],
      removable: true,
      lockable: true,
      locked: false
    },
    'digad-channel': {
      type: 'digad-channel',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'digad-provider-v5': {
      type: 'digad-provider-v5',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'digad-provider': {
      type: 'digad-provider',
      available: [27,20,30,4,12,1,22,28,26,29,30],
      selected: [
        { value: 6, display: 'Adpearance' },
        { value: 4, display: 'Dealer eProcess' },
        { value: 12, display: 'Dealer Inspire' },
        { value: 15, display: 'Dealer Teamwork' },
        { value: 1, display: 'Dealer.com' },
        { value: 3, display: 'DealerOn' },
        { value: 19, display: 'Force Marketing' },
        { value: 7, display: 'Netsertive' },
        { value: 9, display: 'PureCars' },
        { value: 11, display: 'Socal Media Group' },
        { value: 31, display: 'Stream'},
        { value: 23, display: 'Team Velocity' },
        { value: 32, display: 'ZeroSum' }
      ],

      removable: true,
      lockable: true,
      locked: false
    },
    'digital-retailing-provider': {
      type: 'digital-retailing-provider',
      available: [27,20,30,4,12,1,22,28,26,29,30],
      selected: [
        { value: 27, display: 'AutoFi' },
        { value: 20, display: 'CarNow' },
        { value: 30, display: 'Darwin' },
        { value: 4, display: 'Dealer eProcess' },
        { value: 12, display: 'Dealer Inspire' },
        { value: 1, display: 'Dealer.com' },
        { value: 22, display: 'Gubagoo' },
        { value: 28, display: 'Upstart' },
        { value: 26, display: 'Roadster' },
        { value: 29, display: 'WebBuy' },
        { value: 30, display: 'Darwin'}
      ],

      removable: true,
      lockable: true,
      locked: false
    },
    'lead-type': {
      type: 'lead-type',
      selected: [{ value: 1, display: 'New Sales' }],
      removable: false,
      lockable: true,
      locked: false
    },
    'reputation-provider': {
      type: 'reputation-provider',
      available: [17,16,18],
      selected: [
        { value: 17, display: 'Digital Air Strike' },
        { value: 16, display: 'Kenect' },
        { value: 18, display: 'Podium' },
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'reputation-platform': {
      type: 'reputation-platform',
      available: [1,5],
      selected: [
        { value: 1, display: 'Facebook' },
        { value: 5, display: 'Google' },
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'sale-type': {
      type: 'sale-type',
      selected: [{ value: 1, display: 'New' }],
      removable: false,
      lockable: true,
      locked: false
    },
    'social-media-platform': {
      type: 'social-media-platform',
      available: [1,4,3,2],
      selected: [
        { value: 1, display: 'Facebook' },
        { value: 4, display: 'Instagram' },
        { value: 3, display: 'Twitter' },
        { value: 2, display: 'Youtube' },
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'social-media-provider': {
      type: 'social-media-provider',
      available: [17,16],
      selected: [
        { value: 17, display: 'Digital Air Strike' },
        { value: 16, display: 'Kenect' },
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'source-type': {
      type: 'source-type',
      selected: [
        { value: 1, display: 'Brand' },
        { value: 2, display: '3rd Party' },
        { value: 3, display: 'Dealer Website' },
        { value: 7, display: 'Digital Retailing' }
      ],
      removable: false,
      lockable: true,
      locked: false
    },
    'vehicle-make': {
      type: 'vehicle-make',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'vehicle-class': {
      type: 'vehicle-class',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'vehicle-model': {
      type: 'vehicle-model',
      selected: [],
      removable: true,
      lockable: true,
      locked: false
    },
    'website-provider': {
      type: 'website-provider',
      available: [1,2,3,4,12,11,23,24],
      selected: [
        { value: 1, display: 'Dealer.com' },
        { value: 2, display: 'Dealer Fire' },
        { value: 3, display: 'DealerOn' },
        { value: 4, display: 'Dealer eProcess' },
        { value: 12, display: 'Dealer Inspire' },
        { value: 11, display: 'Sokal Media Group' },
        { value: 23, display: 'Team Velocity' },
        { value: 24, display: 'fusionZone' },
      ],
      removable: false,
      lockable: true,
      locked: false
    },
  };
}
